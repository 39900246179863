import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { AbstractBaseComponent } from "@shared/abstracts/abstract-base.component";
import { AuthService } from "@shared/auth/auth.service";
import { ConstantService } from "@shared/constant/shared.constant";
import { CustomCalendlyService } from "@shared/_services/custom-calendly-service";

declare let Calendly: any;

@Component({
  selector: "shared-custom-calendly-widget",
  templateUrl: "./custom-calendly-widget.component.html",
  styles: [
    `
      .bton:hover {
        color: white;
      }
      @media (max-width: 576px) {
        .bton {
          font-size: 12px;
        }
      }
    `,
  ],
})
export class CustomCalendlyWidgetComponent extends AbstractBaseComponent {
  // calendlyCssUrl = ['https://calendly.com', 'assets/external/widget.css'].join('/');
  private promise: Promise<any>;
  @Input() calendlyURL = ConstantService.get("calendlyUrl");
  private readonly calendlyUrl = (url, email, name) =>
    `${url}?name=${name}&email=${email}&a1=${this.companyName}`;
  @Input() buttonText = "Free demo";
  @Input() showIcon = false;
  @Input() companyName = "";
  @Input() hideButton = false;
  @Input() styleClass;
  // @Input() customClass: string = "";
  constructor(
    public sanitizer: DomSanitizer,
    private customCalendlyService: CustomCalendlyService,
    private authService: AuthService
  ) {
    super();
  }

  OnInit(): void {
    this.promise = this.customCalendlyService.load();
  }

  loadPopup() {
    this.promise.then(() => {
      const isUserLoggedIn = this.authService.isUserLoggedIn();
      if (isUserLoggedIn) {
        const loggedInUser = this.authService.getLoggedInUser();
        Calendly.showPopupWidget(
          this.calendlyUrl(
            this.calendlyURL,
            loggedInUser.email,
            loggedInUser.firstName
          )
        );
      } else {
        Calendly.showPopupWidget(this.calendlyUrl(this.calendlyURL, "", ""));
      }
    });
  }
}
