<!-- <link [href]="sanitizer.bypassSecurityTrustResourceUrl(calendlyCssUrl)" rel="stylesheet"> -->
<div
  (click)="loadPopup()"
  class="bton w-100 rounded15 bg-cyan-1 text-cyan py-2 px-3 font16 fw-700"
  [hidden]="hideButton"
  [ngClass]="styleClass"
>
  <span style="">
    <!-- <img src="{{ assetPath }}/images/new-svg/demoicon.svg" alt="" /> -->
    {{ buttonText }}
  </span>
</div>
