<!-- <section id="newsletter">
  <div class="container">
    <div class="row">
      <div class="col-xl-4 offset-xl-4 col-md-6 offset-md-3">
        <form
          [formGroup]="subscriptionForm"
          (submit)="subscriptionFormSubmit()"
          autocomplete="off"
        >
          <h6 class="text-center">Newsletter</h6>
          <div class="fieldDiv row no-gutters" *ngIf="!subscriptionSuccess">
            <div class="col-8">
              <input
                [ngClass]="{ 'text-success': subscriptionError }"
                autocomplete="off"
                name="box_email"
                formControlName="email"
                type="email"
                placeholder="Email Id"
                (change)="changeEmailID()"
              />
            </div>
            <div class="col-4">
              <button type="submit" class="btn btn-pinks btn-block">
                Subscribe
              </button>
            </div>
          </div>
          <control-messages
            [control]="subscriptionForm.controls.email"
          ></control-messages>
          <div *ngIf="subscriptionSuccess" class="text-center font16">
            {{ "subscription.email.success" | translate }}
          </div>
          <div *ngIf="subscriptionError" class="font10">
            {{ "subscription.email.error" | translate }}
          </div>
        </form>
      </div>
    </div>
  </div>
</section> -->
<footer>
  <div class="d-lg-flex custom-flex-container">
    <div class="d-md-flex d-lg-block col-lg-5 col-md-12">
      <div class="col-md-6">
        <div class="px-0">
          <img
            src="{{ assetPath }}/images/footer/logo.svg"
            title="BOXXPORT.com Logo"
            alt="BOXXPORT.com online marketplace logo"
          />
        </div>
        <div class="d-flex col-lg-12 pl-0 mt-3 justify-content-between">
          <!-- <div>
            <img src="{{ assetPath }}/images/footer/whatsapp-icn.svg" alt="" />
          </div> -->
          <div>
            <a
              href="https://www.instagram.com/boxxport"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="{{ assetPath }}/images/footer/insta-icn.svg"
                alt="Instagram"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/company/boxxport"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="{{ assetPath }}/images/footer/linkedin-icn.svg"
                alt=""
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/BOXXPORT.gmbh/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="{{ assetPath }}/images/footer/facebook-icn.svg"
                alt=""
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.youtube.com/channel/UCMmv0wqNicxz-V2yyWydkhg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="{{ assetPath }}/images/footer/youtube-icn.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 newsletter-container mt-lg-4">
        <span
          class="d-flex mt-4 mt-md-0 font-freightSans-pro news font22 fw-350 text-blue-lighter"
          >Sign our Newsletter</span
        >
        <div class="subscription-container mt-3">
          <form
            [formGroup]="subscriptionForm"
            (submit)="subscriptionFormSubmit()"
            autocomplete="off"
          >
            <div class="fieldDiv" *ngIf="!subscriptionSuccess">
              <input
                class="subscription-input"
                [ngClass]="{ 'text-success': subscriptionError }"
                autocomplete="off"
                name="box_email"
                formControlName="email"
                type="email"
                placeholder="Email Id"
                (change)="changeEmailID()"
              />
              <button type="submit" class="subscription-button mx-2 my-2">
                Subscribe
              </button>
            </div>
            <control-messages
              [control]="subscriptionForm.controls.email"
            ></control-messages>
            <div *ngIf="subscriptionSuccess" class="text-center font16">
              {{ "subscription.email.success" | translate }}
            </div>
            <div *ngIf="subscriptionError" class="font10">
              {{ "subscription.email.error" | translate }}
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-7 col-md-12 mt-4 mt-lg-0">
      <div class="d-md-flex">
        <div class="col-xl-4 col-md-3 mt-3 mt-lg-0 mt-md-0">
          <span class="font14 fw-700 font-freightSans-pro text-blue-lightest2"
            >Tools for Container Traders</span
          >

          <div class="py-1">
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['buy-shipping-containers']"
              >Buying on Boxxport</a
            >
          </div>

          <div>
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['sell-shipping-containers']"
              [ngStyle]="{ 'line-height': '19.2px' }"
              >Selling on Boxxport</a
            >
          </div>

          <div class="py-1">
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['buyback-shipping-containers']"
              [ngStyle]="{ 'line-height': '19.2px' }"
              >BuyBack</a
            >
          </div>
          <div>
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['buy-sell-negotiations']"
              >Negotiations</a
            >
          </div>
          <div class="py-1">
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['radar-shipping-containers']"
              >Container Radar</a
            >
          </div>
          <div>
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['container-kaufen-de']"
              >Containers + Transport</a
            >
          </div>
          <div class="py-1">
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['eWallet']"
              >e-Wallet</a
            >
          </div>
        </div>
        <div class="col-xl-3 col-sm-3 col-md-3 px-md-0 mt-3 mt-lg-0 mt-md-0">
          <span class="font14 fw-700 font-freightSans-pro text-blue-lightest2"
            >Company</span
          >

          <div class="py-1">
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['aboutUs']"
              >About us</a
            >
          </div>
          <div>
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['ourTeam']"
              >Our Team</a
            >
          </div>
          <div class="py-1">
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['/careers']"
              >Careers</a
            >
          </div>
          <div>
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['/meet-us']"
              >Meet us</a
            >
          </div>
          <div class="py-1">
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['/blogs']"
              >Blog</a
            >
          </div>
          <div>
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              target="_blank"
              [routerLink]="['/academy']"
              >Academy</a
            >
          </div>
        </div>
        <div class="col-xl-5 col-sm-5 mt-3 mt-lg-0 mt-md-0">
          <span class="font14 fw-700 font-freightSans-pro text-blue-lightest2"
            >Most searched locations</span
          >

          <div class="py-1">
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/china']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in <span class="fw-800">China</span>
            </a>
          </div>
          <div>
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/usa']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in <span class="fw-800">USA</span>
            </a>
          </div>
          <div class="py-1">
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/germany']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in <span class="fw-800">Germany</span>
            </a>
          </div>
          <div>
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/poland']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in <span class="fw-800">Poland</span>
            </a>
          </div>
          <div class="py-1">
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/india']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in <span class="fw-800">India</span>
            </a>
          </div>
          <div>
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/canada']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in <span class="fw-800">Canada</span>
            </a>
          </div>
          <div class="py-1">
            <a
              class="font16 fw-400 text-blue-lighter lineHeight19"
              href="javascript:void(0);"
              [routerLink]="['buyer/shipping-containers/spain']"
              (click)="goToListing()"
            >
              Buy Shipping Containers in <span class="fw-800">Spain</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="px-3 mt-5">
    <div class="bg-blue-dark-2 rounded4 px-lg-5 px-4 px-md-5 py-5 company-info">
      <!-- Desktop Version -->
      <div
        class="d-lg-flex d-none justify-content-between align-items-end flex-wrap"
      >
        <div class="text-align-left">
          <h6 class="font24 fw-350 font-freightSans-pro text-blue-lightest10">
            Your container Marketplace
          </h6>
          <p class="text-blue-lighter font14 fw-400">
            Grimm 14, 20457 Hamburg | hello@boxxport.com | +49(0)40 311 71 25 0
          </p>
        </div>
        <div class="text-align-right">
          <p class="text-blue-lighter font14 fw-400">
            © BOXXPORT Trading GmbH copyright reserved
          </p>
        </div>
      </div>

      <!-- Mobile Version -->
      <div class="d-lg-none d-flex flex-column">
        <h6 class="font24 fw-350 font-freightSans-pro text-blue-lightest10">
          Your container Marketplace
        </h6>
        <p class="text-blue-lighter font14 fw-400">
          Grimm 14, 20457 Hamburg <br />
          hello@boxxport.com <br />
          +49(0)40 311 71 25 0
        </p>
        <p class="text-blue-lighter font14 fw-400 mt-3">
          © BOXXPORT GmbH copyright reserved
        </p>
      </div>
    </div>

    <div class="footer-links d-flex justify-content-center font17 fw-400 mt-3">
      <div>
        <a
          class="text-blue-lighter px-2"
          target="_blank"
          [routerLink]="['imprint']"
          >Imprint</a
        >
      </div>
      <div class="vl">
        <a
          class="text-blue-lighter px-2"
          target="_blank"
          [routerLink]="['privacy-policy']"
          >Privacy Policy</a
        >
      </div>
      <div class="vl">
        <a
          class="text-blue-lighter px-2"
          target="_blank"
          [routerLink]="['terms']"
          >Terms</a
        >
      </div>
      <div class="vl negotiation-policy">
        <a
          class="text-blue-lighter px-2"
          target="_blank"
          [routerLink]="['negotiation-policy']"
          >Negotiation Policy</a
        >
      </div>
    </div>
  </div>

  <div class="text-center text-white small" [class.hideData]="!isDev">
    <hr />
    {{ version }}
    <div>
      <span *ngIf="effectiveType"
        >(Connection Type :: {{ effectiveType }})</span
      >
      <span *ngIf="downlinkMax">(Connection Speed :: {{ downlinkMax }})</span>
    </div>
  </div>
</footer>
