<div *ngIf="notification">
  <div class="d-lg-flex d-md-flex">
    <div
      class="col-lg-8 col-md-8 px-0 font14 fw-400"
      [ngClass]="{
        'text-read': notification.isRead,
        'text-unread': !notification.isRead
      }"
      [innerHTML]="notification.heading"
    ></div>
    <div class="d-flex col-lg-4 col-md-4 justify-content-end px-0">
      <div class="font14 fw-400 text-blue-lighter">
        {{ notificationTime(notification) | date : "dd-MMM-yyyy / hh:mm a" }}
      </div>
      <div class="ml-2" (click)="listingActionClick($event)">
        <shared-notification-widget-listing-item-action
          [userType]="userType"
          [config]="config"
          [notification]="notification"
        >
        </shared-notification-widget-listing-item-action>
      </div>
    </div>
  </div>
</div>
