<!-- <marketplace-demo-nav-bar></marketplace-demo-nav-bar> -->
<header id="home">
  <nav
    class="navbar navbar-expand-lg bg-blue px-lg-5"
    (mouseleave)="isCollapse = true"
  >
    <a
      class="pl-2 pl-lg-0"
      [routerLink]="['/']"
      href="javascript:void(0);"
      (click)="isCollapse = true"
      ><img
        class="newboxxportlogo d-none d-lg-block"
        src="{{ assetPath }}/images/new-svg/newboxxportlogo.svg"
        alt="Boxxport logo" />
      <div class="bg-white p-1 d-block d-lg-none">
        <img
          class="boxxportlogo"
          src="{{ assetPath }}/images/new-svg/boxxportlogo.svg"
          alt="Boxxport logo"
        /></div
    ></a>

    <div
      class="bton d-none d-lg-inline ml-lg-5 rounded15 bg-cyan-1 text-cyan py-2 px-3 font16 fw-700"
      [routerLink]="['/buyer/product/listing']"
      role="button"
    >
      <img
        src="{{ assetPath }}/images/header/new-cyan-magnifying.png"
        class="marginR10"
        alt=""
      />Global Search
    </div>
    <button
      class="navbar-toggler collapsed ml-1 mr-auto"
      (click)="isCollapse = !isCollapse"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
      <span class="navbar-toggler-icon"></span>
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      *ngIf="isLoggedIn"
      class="d-lg-none d-flex flex-row pr-2 nav-item position-static"
    >
      <ng-template [ngTemplateOutlet]="notificationsDropdown"></ng-template>
      <ng-template [ngTemplateOutlet]="accountDropdown"></ng-template>
      <ng-template [ngTemplateOutlet]="cartDropdown"></ng-template>
    </div>
    <li
      *ngIf="!isLoggedIn"
      class="nav-item d-block d-md-none mr-2"
      (click)="isCollapse = true"
    >
      <marketplace-get-started-action></marketplace-get-started-action>
    </li>
    <div class="marginL10 d-none d-lg-inline px-3 py-2" role="button">
      <shared-custom-calendly-widget></shared-custom-calendly-widget>
    </div>

    <div
      class="navbar-collapse"
      id="navbarNav"
      [ngClass]="{ collapse: isCollapse }"
    >
      <ul class="nav align-items-center p-0">
        <li class="nav-item dropdown transparent products-services"></li>
        <li
          class="nav-item dropdown transparent products-services"
          (mouseenter)="closeAllDropdowns(); mouseAction('productsServices')"
        >
          <a
            class="nav-link m-0 font14 fw-500"
            [ngClass]="{ show: whyboxxport }"
            attachOutsideOnClick="true"
            (clickOutside)="onClickedOutside($event, 'whyboxxport')"
            (click)="whyboxxport = !whyboxxport"
            role="button"
            id="whyboxxport"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            >Products & Services</a
          >
          <div class="dropdown-wrapper" (mouseleave)="whyboxxport = false">
            <div
              class="dropdown-menu"
              [ngClass]="{ show: whyboxxport }"
              aria-labelledby="whyboxxport"
              (mouseleave)="whyboxxport = false"
            >
              <div class="d-flex flex-column flex-lg-row">
                <div>
                  <h2 class="text-grey-blue font16 fw-900">
                    Trade Better and Faster
                  </h2>
                  <div class="products-grid">
                    <a
                      *ngFor="let tab of producttabs"
                      (click)="isCollapse = true"
                      class="dropdown-item"
                      [routerLink]="[tab.link]"
                      routerLinkActive="active"
                    >
                      <div class="row m-0">
                        <img alt="" class="p-0 col-1" src="{{ tab.image }}" />
                        <div class="col-11 pl-2 font14 fw-900">
                          {{ tab.heading }}
                          <p class="text-blue-light m-0 text-wrap fw-400">
                            {{ tab.desc }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="vl ml-lg-4 ml-0 pl-lg-4 pl-0 pt-4 pt-lg-0">
                  <h2 class="text-grey-blue font16 fw-900">
                    Future of Container Trading
                  </h2>
                  <div class="products-grid">
                    <a
                      *ngFor="let tab of servicetabs"
                      (click)="isCollapse = true"
                      class="dropdown-item"
                      [routerLink]="[tab.link]"
                      routerLinkActive="active"
                    >
                      <div class="row m-0">
                        <img alt="" class="p-0 col-1" src="{{ tab.image }}" />
                        <div class="col-11 pl-2 font14 fw-900">
                          {{ tab.heading }}
                          <p class="text-blue-light m-0 text-wrap fw-400">
                            {{ tab.desc }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li
          class="nav-item dropdown transparent"
          (mouseenter)="closeAllDropdowns(); mouseAction('company')"
        >
          <a
            class="nav-link m-0 font14 fw-500"
            [ngClass]="{ show: ourCompany }"
            attachOutsideOnClick="true"
            (clickOutside)="onClickedOutside($event, 'ourCompany')"
            (click)="ourCompany = !ourCompany"
            role="button"
            id="ourCompany"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            >Company</a
          >
          <!-- <hr /> -->
          <div class="dropdown-wrapper">
            <div
              class="dropdown-menu"
              [ngClass]="{ show: ourCompany }"
              aria-labelledby="ourCompany"
              role="button"
              role="button"
              (mouseleave)="ourCompany = false"
            >
              <h2 class="text-grey-blue text-nowrap font16 fw-900">
                Your Container Marketplace
              </h2>
              <!-- <a
                *ngFor="let tab of companytabs"
                (click)="
                  isCollapse = !isCollapse;
                  onClickLink(tab.link, tab.isExternal)
                "
                class="dropdown-item"
                href="javascript:void(0)"
                routerLinkActive="active"
              >
                <div class="d-flex align-items-center">
                  <img class="pr-2" src="{{ tab.image }}" />
                  <div>
                    {{ tab.heading }}
                    <p class="text-blue-light text-wrap">{{ tab.desc }}</p>
                  </div>
                </div>
              </a> -->
              <ng-container *ngFor="let tab of companytabs">
                <a
                  *ngIf="!tab.isExternal; else isExternalCompanyItem"
                  [routerLink]="tab.link"
                  (click)="isCollapse = true"
                  class="dropdown-item {{ tab.isExternal ? tab.link : null }}"
                  routerLinkActive="active"
                >
                  <ng-template
                    [ngTemplateOutlet]="companyMenuItem"
                    [ngTemplateOutletContext]="{ tab: tab }"
                  ></ng-template>
                </a>
                <ng-template #isExternalCompanyItem>
                  <a
                    [href]="tab.link"
                    target="_blank"
                    class="dropdown-item {{ tab.isExternal ? tab.link : null }}"
                  >
                    <ng-template
                      [ngTemplateOutlet]="companyMenuItem"
                      [ngTemplateOutletContext]="{ tab: tab }"
                    ></ng-template> </a
                ></ng-template>
              </ng-container>
            </div>
          </div>
          <hr class="mb-0 mt-0" />
        </li>
      </ul>
      <ul class="nav ml-auto p-0">
        <!-- <li class="nav-item d-lg-none">
          <a
            class="nav-link"
            [routerLink]="['/buyer/product/listing']"
            routerLinkActive="hideData"
          >
            <span class="icn-search"></span>
            <span
              class="d-inline d-lg-none marginL10 gtm-header-container-search"
              >Search All</span
            >
          </a>
        </li> -->
        <li class="nav-item d-none" *ngIf="isLoggedIn">
          <a
            (click)="goToHelp()"
            target="_blank"
            class="nav-link"
            role="button"
          >
            <span class="text-cyan bold">Help?</span>
          </a>
        </li>
        <div class="d-none d-lg-flex flex-row">
          <li class="nav-item" *ngIf="!isLoggedIn">
            <a
              (click)="goToHelp()"
              target="_blank"
              class="nav-link"
              role="button"
            >
              <span class="text-cyan bold"><u class="mt-1">Help?</u></span>
            </a>
          </li>

          <li class="nav-item" *ngIf="isLoggedIn">
            <ng-template
              [ngTemplateOutlet]="notificationsDropdown"
            ></ng-template>
          </li>
          <li *ngIf="isLoggedIn" class="nav-item">
            <ng-template [ngTemplateOutlet]="accountDropdown"></ng-template>
          </li>
          <!-- <li class="nav-item">
          <a href="{{ url }}" target="_blank" class="nav-link">
            <span class="text-blue bold">Help?</span>
          </a>
        </li> -->

          <li *ngIf="isLoggedIn" class="nav-item cardOnLg">
            <ng-template [ngTemplateOutlet]="cartDropdown"></ng-template>
          </li>
          <li
            *ngIf="!isLoggedIn"
            class="nav-item d-none d-lg-inline"
            (click)="isCollapse = !isCollapse"
          >
            <marketplace-get-started-action></marketplace-get-started-action>
          </li>
          <li *ngIf="!isLoggedIn">
            <a
              [queryParams]="queryParams"
              (click)="router.navigate(['/login'])"
              [fragment]="isSeller ? 'seller' : 'buyer'"
              class="nav-link inline"
              role="button"
            >
              <span class="text-cyan bold"><u>Sign In</u></span>
            </a>
          </li>
        </div>
        <div
          class="d-flex justify-content-center align-items-center w-100 bg-white"
        >
          <div
            class="col-9 d-inline d-lg-none w-100 px-3 px-lg-0 pb-3 pb-lg-0 pt-3 pt-lg-0"
          >
            <div
              class="d-flex justify-content-around w-100"
              *ngIf="!isLoggedIn"
            >
              <li>
                <a
                  [queryParams]="queryParams"
                  (click)="router.navigate(['/login'])"
                  [fragment]="isSeller ? 'seller' : 'buyer'"
                  (click)="isCollapse = true"
                  class="nav-link inline"
                  role="button"
                >
                  <span class="text-cyan bold"><u>Sign In</u></span>
                </a>
              </li>
              <li>
                <a
                  (click)="goToHelp()"
                  target="_blank"
                  class="nav-link"
                  (click)="isCollapse = true"
                  role="button"
                >
                  <span class="text-blue-lighter bold"><u>Help?</u></span>
                </a>
              </li>
            </div>
            <a
              class="bton rounded15 bg-cyan text-white ml-lg-5 w-100 mb-2 px-3 py-3 d-flex align-items-center justify-content-center"
              [routerLink]="['/buyer/product/listing']"
              (click)="isCollapse = true"
            >
              <img
                src="{{ assetPath }}/images/new-svg/new-magnifying.svg"
                class="marginR10"
                alt=""
              />Global Search</a
            >
            <div
              *ngIf="calendly"
              (click)="calendly.loadPopup()"
              class="bton rounded15 bg-cyan-1 text-cyan w-100 px-3 d-flex align-items-center justify-content-center py-3"
            >
              <span style="margin-right: 10px">
                <!-- <img
                src="{{ assetPath }}/images/new-svg/bluedemoicon.svg"
                alt="Schedule demo"
              /> -->
                {{ calendly?.buttonText }}
              </span>
            </div>
            <shared-custom-calendly-widget
              [hideButton]="true"
              #customCalendlyWidget
            ></shared-custom-calendly-widget>
          </div>
        </div>
      </ul>
    </div>
  </nav>
</header>
<div class="navbar interModal justify-content-center">
  <div class="d-flex py-1 py-lg-2">
    <a class="" target="_blank"
      ><p class="font-freightSans-pro text-blue-dark font18 fw-700 mb-0">
        Containers <span class="text-color-pink">incl.</span> Transport
        <span class="text-blue-lighter fw-400">in Germany.</span>
        <button
          role="button"
          [routerLink]="['/container-kaufen-de']"
          class="btn btn-pink ml-3 font14 fw-700"
        >
          Try it now
        </button>
      </p>
    </a>
  </div>
</div>
<ng-template #accountDropdown>
  <a
    class="nav-link profile"
    attachOutsideOnClick="true"
    (clickOutside)="onClickedOutside($event, 'isSubMenu')"
    (click)="isSubMenu = !isSubMenu; isCollapse = true"
    (mouseenter)="closeAllDropdowns(); mouseAction('account')"
    role="button"
    id="userNav"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <img src="{{ assetPath }}/images/new-svg/usericon.svg" alt="Profile" />
    <!-- <span class="d-inline d-lg-none marginL10">Account</span> -->
  </a>
  <div class="dropdown-wrapper profile">
    <div
      (mouseleave)="isSubMenu = false"
      class="dropdown-menu"
      [ngClass]="{ show: isSubMenu }"
      aria-labelledby="userNav"
    >
      <div class="py-3 py-lg-0"></div>
      <div class="px-lg-0 px-4" *ngIf="isLoggedIn">
        <div class="d-flex col-12 px-0" *ngFor="let company of companyList">
          <div
            class="bg-blue-lightest-2 px-1 py-1 rounded1 text-wrap font16 fw-700 text-blue-light"
            *ngIf="company.isDefaultOrg"
          >
            {{ company.companyName }}
          </div>
        </div>
        <div class="font12 fw-400 text-blue-lighter">
          All shown information is linked to this company.
        </div>
        <div class="py-2" role="button" [routerLink]="['/profile/org/listing']">
          <img src="{{ assetPath }}/images/header/switch-account.png" alt="" />
          <span class="text-sky-lightest font14 fw-600 ml-1"
            >Switch default company</span
          >
        </div>
      </div>
      <a
        *ngFor="let tab of profiletabs"
        class="dropdown-item"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="[tab.link]"
      >
        <div class="d-flex align-items-center my-2">
          <img class="pr-2" src="{{ tab.image }}" alt="" />
          <div>
            <span class="font18 fw-800" (click)="isSubMenu = false">
              {{ tab.tabName }}</span
            >
          </div>
        </div>
      </a>
      <div class="py-4"></div>
      <div class="px-4 px-lg-0">
        <a
          (click)="goToHelp()"
          target="_blank"
          role="button"
          class="btn btn-cyan2-outline d-block d-lg-none my-2"
        >
          <span class="text-cyan bold">Help?</span>
        </a>
        <div
          class="bg-sky-lightest1 text-sky-lightest text-center py-2"
          style="border-radius: 360px"
          [routerLink]="['']"
          (click)="onLoggedout()"
          role="button"
        >
          Sign out
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #cartDropdown>
  <!-- <a class="nav-link cart" [routerLink]="['/buyer/cart']">
    <span class="icn-cart marginT5"></span>
    <span class="badge badge-pill">
      <marketplace-cart-icon></marketplace-cart-icon>
    </span>
  </a> -->
  <a class="nav-link cart" [routerLink]="['/buyer/cart']">
    <span class=""
      ><img src="{{ assetPath }}/images/new-svg/carticon.svg" alt="Cart"
    /></span>
    <span class="badge badge-pill">
      <marketplace-cart-icon></marketplace-cart-icon>
    </span>
  </a>
</ng-template>
<ng-template #notificationsDropdown>
  <a href="javascript:void(0);" class="nav-link" role="button">
    <shared-notification-widget-count
      (closeAllDropdownsEvent)="closeAllDropdowns(false)"
      [closeNotificationWidget]="closeNotificationWidget"
    ></shared-notification-widget-count>
  </a>
</ng-template>
<ng-template #companyMenuItem let-tab="tab">
  <div class="d-flex align-items-center">
    <img alt="" class="pr-2" src="{{ tab.image }}" />
    <div class="font14 fw-900">
      {{ tab.heading }}
      <p class="text-blue-light text-wrap fw-400">{{ tab.desc }}</p>
    </div>
  </div>
</ng-template>
