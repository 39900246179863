<div class="noti-show py-4 px-4">
  <div *ngIf="notificationMap?.size === 0">
    <div class="d-flex">
      <div class="col-12 px-0 text-center py-5 text-color">
        <span *ngIf="isLoading">Loading....</span>
        <shared-notification-widget-no-notification
          *ngIf="!isLoading"
        ></shared-notification-widget-no-notification>
      </div>
    </div>
  </div>
  <ng-container *ngIf="notificationMap.size > 0">
    <div class="row">
      <div class="col-8">
        <span class="fw-500 font-freightSans-pro font24 margin0 text-color"
          >Notifications</span
        >
      </div>
      <div
        class="col-4 font16 fw-800 text-right text-sky-light marginT5"
        *ngIf="notificationMap?.size"
      >
        <a href="javascript:void(0)" (click)="markAsAllRead()"
          >Mark all as read</a
        >
      </div>
    </div>
    <!-- TABS -->
    <!-- <div class="tabs-container">
      <ul class="nav nav-tabs">
        <li class="nav-item" *ngFor="let tab of tabs">
          <a
            class="nav-link"
            [class.active]="selectedTab === tab.key"
            (click)="selectTab(tab.key)"
          >
            {{ tab.label }}
          </a>
        </li>
      </ul>
    </div> -->
    <div class="tabs-container">
      <div class="nav nav-tabs col-12">
        <div class="nav-item col-4" *ngFor="let tab of tabs">
          <a
            class="nav-link"
            [class.active]="selectedTab === tab.key"
            (click)="selectTab(tab.key)"
          >
            {{ tab.label }}
          </a>
        </div>
      </div>
    </div>

    <!-- <hr class="marginT10 marginB0" /> -->
    <ul>
      <!-- <ng-container
        *ngFor="
          let notificationObject of notificationMap | keyvalue : reverseKeyOrder
        "
      >
        <li *ngIf="isShow(notificationObject.value)">
          <shared-notification-widget-listing-small-header
            [config]="config"
            [heading]="notificationObject.key"
          >
          </shared-notification-widget-listing-small-header>
        </li>
        <ng-container *ngFor="let notification of notificationObject.value">
          <li
            [class]="notificationStatus(notification).toLowerCase()"
            *ngIf="notification.status !== 'HIDE'"
          >
            {{ notification.staus }}
            <shared-notification-widget-listing-small-item
              (click)="listingItemClick($event, notification)"
              [userType]="userType"
              [config]="config"
              [notification]="notification"
            >
            </shared-notification-widget-listing-small-item>
          </li>
        </ng-container>
      </ng-container> -->
      <ng-container
        *ngFor="
          let notificationObject of notificationMap | keyvalue : reverseKeyOrder
        "
      >
        <li *ngIf="isShow(notificationObject.value)">
          <shared-notification-widget-listing-small-header
            [config]="config"
            [heading]="notificationObject.key"
          >
          </shared-notification-widget-listing-small-header>
        </li>
        <ng-container *ngFor="let notification of notificationObject.value">
          <!-- Apply filterByTab to only show relevant notifications -->
          <li
            *ngIf="filterByTab(notification) && notification.status !== 'HIDE'"
            [class]="notificationStatus(notification).toLowerCase()"
          >
            <shared-notification-widget-listing-small-item
              (click)="listingItemClick($event, notification)"
              [userType]="userType"
              [config]="config"
              [notification]="notification"
            >
            </shared-notification-widget-listing-small-item>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </ng-container>
  <div class="text-center mt-3 mb-4">
    <a
      (click)="hideWindow()"
      [routerLink]="['/profile/notification']"
      class="font16 fw-800"
    >
      See all Notifications
    </a>
  </div>
</div>
