import { Location } from "@angular/common";
import { Component, Injector } from "@angular/core";
import { PushNotificationUtils } from "@marketplace/utils/PushNotification.utils";
import { TranslateService } from "@ngx-translate/core";
import { NotificationModel } from "../dto/NotificationModel.dto";
import { NotificationWidgetListingComponent } from "../notification-widget-listing/notification-widget-listing.component";

@Component({
  selector: "shared-notification-widget-listing-small",
  templateUrl: "./notification-widget-listing-small.component.html",
  styleUrls: ["./notification-widget-listing-small.component.scss"],
})
export class NotificationWidgetListingSmallComponent extends NotificationWidgetListingComponent {
  constructor(
    protected injector: Injector,
    protected translateService: TranslateService,
    protected location: Location
  ) {
    super(injector, translateService, location);
  }

  tabs = [
    { key: "all", label: "All" },
    { key: "listings", label: "Listings" },
    { key: "orders", label: "Orders" },
    // { key: "orgUser", label: "Org/User" },
  ];

  selectedTab = "all"; // Default tab

  selectTab(tabKey: string) {
    this.selectedTab = tabKey;
  }

  // Filter notifications based on selected tab
  filterByTab(notification: NotificationModel): boolean {
    if (this.selectedTab === "all") {
      return true; // Show all notifications
    } else if (this.selectedTab === "orders") {
      return ["SELLER_ORDER", "BUYER_ORDER"].includes(notification.category);
    } else if (this.selectedTab === "listings") {
      return notification.category === "PRODUCT";
    }
    return false;
  }

  hideWindow() {
    this.hideEvent.emit(true);
  }

  isShow(notificationModels: NotificationModel[]) {
    return notificationModels.find((e) => e.status !== "HIDE");
  }
}
