<main class="main">
  <div class="d-flex align-items-center justify-content-center py-4">
    <div class="rounded4 bg-white col-lg-8 col-12 pt-4 px-4">
      <a
        href="javascript:void(0);"
        (click)="goBack()"
        class="font14 fw-800 marginT10 text-sky-lightest"
        >&lt; Back</a
      >

      <div
        class="marginTB10 font24 fw-500 align-items-center d-flex font-freightSans-pro"
      >
        <img
          class="mr-2"
          src="{{ assetPath }}/images/header/noti-pink.png"
          alt=""
        />Notifications
      </div>

      <!-- Tabs Section -->
      <div class="tabs-container">
        <div class="nav nav-tabs col-12">
          <div class="nav-item col-4 px-0" *ngFor="let tab of tabs">
            <a
              class="nav-link"
              [class.active]="selectedTab === tab.key"
              (click)="selectTab(tab.key)"
            >
              {{ tab.label }}
            </a>
          </div>
        </div>
      </div>

      <div class="px-0">
        <div class="text-center py-5" *ngIf="filteredNotifications.size === 0">
          <div class="col-lg-12 px-0" *ngIf="isLoading">Loading....</div>
          <div class="col-lg-12 px-0" *ngIf="!isLoading">
            <shared-notification-widget-no-notification></shared-notification-widget-no-notification>
          </div>
        </div>
        <div class="" *ngIf="filteredNotifications.size > 0">
          <div class="col-lg-12 px-0">
            <ng-container
              *ngFor="
                let notificationObject of filteredNotifications
                  | keyvalue : reverseKeyOrder
              "
            >
              <div class="font16 fw-800 marginTB20 text-blue-lighter">
                <shared-notification-widget-listing-header
                  [config]="config"
                  [heading]="notificationObject.key"
                >
                </shared-notification-widget-listing-header>
              </div>
              <ul class="notification">
                <li
                  *ngFor="let notification of notificationObject.value"
                  [class]="notificationStatus(notification).toLowerCase()"
                >
                  <shared-notification-widget-listing-item
                    [userType]="userType"
                    [config]="config"
                    [notification]="notification"
                    (click)="listingItemClick($event, notification)"
                  >
                  </shared-notification-widget-listing-item>
                </li>
              </ul>
            </ng-container>
            <div
              *ngIf="(page + 1) * size < notificationCount?.total"
              class="d-flex align-items-center justify-content-center text-center mt-4 mb-4"
            >
              <div
                class="col-3 action load font16 fw-800 cursorPointer px-5 py-2"
                (click)="seeMore()"
              >
                <span class="">Load more</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
