import { KeyValue, Location } from "@angular/common";
import { Component, EventEmitter, Injector, Output } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import TimeAgo from "javascript-time-ago";
// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";
import { forkJoin } from "rxjs";
import { finalize } from "rxjs/operators";
import { AbstractNotificationWidgetComponent } from "../abstract/abstract-notification-widget.component";
import { NotificationCountModel } from "../dto/NotificationCountModel.dto";
import { NotificationModel } from "../dto/NotificationModel.dto";

// https://www.npmjs.com/package/javascript-time-ago
// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en);

const timeAgo = new TimeAgo("en-US");

@Component({
  selector: "shared-notification-widget-listing",
  templateUrl: "./notification-widget-listing.component.html",
  styleUrls: ["./notification-widget-listing.component.scss"],
})
export class NotificationWidgetListingComponent extends AbstractNotificationWidgetComponent {
  protected router: Router;

  isLoading = true;

  @Output() hideEvent: EventEmitter<boolean> = new EventEmitter();

  notificationMap: Map<String, NotificationModel[]> = new Map<
    String,
    NotificationModel[]
  >();

  page = 0;

  size = 10;

  notificationCount: NotificationCountModel;

  tabs = [
    { key: "all", label: "All" },
    { key: "orders", label: "Orders" },
    { key: "listings", label: "Listings" },
  ];

  selectedTab = "all";
  filteredNotifications: Map<String, NotificationModel[]> = new Map<
    String,
    NotificationModel[]
  >();

  constructor(
    protected injector: Injector,
    protected translateService: TranslateService,
    protected location: Location
  ) {
    super(injector);
    this.router = injector.get(Router);
    this.translateService = injector.get(TranslateService);
  }

  goBack() {
    this.location.back();
  }

  protected OnChatWidgetInit() {
    super.OnChatWidgetInit();
    const page = this.page;
    const size = this.size;
    const filter = Object.assign({ size, page }, this.filter || {});
    const listPromise = this.notificationWidgetService.list(
      this.userType,
      this.status,
      filter
    );
    const countPromise = this.notificationWidgetService.count(
      this.userType,
      this.status,
      filter
    );
    this.startBlockUi();
    forkJoin([listPromise, countPromise])
      .pipe(
        finalize(() => {
          this.stopBlockUi();
          this.isLoading = false;
        })
      )
      .subscribe(([listResult, countResult]) => {
        if (listResult && listResult.result) {
          listResult.result.forEach((notification) => {
            const key = this.toKey(notification).toString();
            notification.heading = this.translateService.instant(
              "notification." + notification.metadata.status + ".header",
              notification.metadata
            );
            let notificationArr = this.notificationMap.get(key);
            if (!notificationArr) {
              notificationArr = [];
            }
            notificationArr.push(notification);
            this.notificationMap.set(key, notificationArr);
          });
        }
        this.notificationCount = countResult.result;
        this.filterNotifications();
      });
  }

  markAsAllRead() {
    const notificationArrArr = Array.from(this.notificationMap.values());
    const notifications = [];
    notificationArrArr.forEach((notificationArr) => {
      notificationArr.forEach((e) => {
        notifications.push(e);
      });
    });
    super.updateStatus(this.statusRead, notifications);
  }

  selectTab(tabKey: string) {
    this.selectedTab = tabKey;
    this.filterNotifications();
  }

  filterNotifications() {
    this.filteredNotifications.clear();
    this.notificationMap.forEach((notifications, key) => {
      let filteredList = notifications;

      if (this.selectedTab === "orders") {
        filteredList = notifications.filter(
          (n) => n.category === "SELLER_ORDER" || n.category === "BUYER_ORDER"
        );
      } else if (this.selectedTab === "listings") {
        filteredList = notifications.filter((n) => n.category === "PRODUCT");
      }

      if (filteredList.length > 0) {
        this.filteredNotifications.set(key, filteredList);
      }
    });
  }

  private toKey(notification) {
    const date = notification.date;
    const dt = new Date();
    dt.setHours(0, 0, 0, 0);
    const now = dt.getTime();
    const tommorow = now - 24 * 60 * 60 * 1000;
    return date > now
      ? "New"
      : date > tommorow
      ? "Yesterday"
      : timeAgo.format(notification.date);
  }

  seeMore() {
    this.page++;
    this.OnChatWidgetInit();
  }

  listingItemClick($event, notification: NotificationModel) {
    let route;
    const category =
      notification && notification.category ? notification.category : "default";
    if (category) {
      switch (category) {
        case "ORDER":
          route = `/buyer/orders/details/${notification.metadata.orderIdentifier}`;
          break;
        default:
          const anchors = $event.target.querySelectorAll("a");
          if (anchors && anchors.length > 0) {
            route = anchors[0].attributes.href.value;
          }
          break;
      }
    }
    if (route) {
      this.router.navigate([]).then((result) => {
        window.open(route, "_blank");
      });
    }
    this.hideEvent.emit(true);
  }

  reverseKeyOrder = (
    a: KeyValue<string, string>,
    b: KeyValue<string, string>
  ): number => {
    const value = (val) => {
      return val.value[0].date;
    };
    const o1 = value(a);
    const o2 = value(b);
    return o1 > o2 ? -1 : o2 > o1 ? 1 : 0;
  };
}
